<template>
  <v-container fluid>

    <v-card elevation="0">
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        {{ !!this.printer && parseInt(this.printer.type) == 1 ? $t('settings.lang_BonDrucker') : $t('settings.lang_orderPrinter') }}
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-progress-circular v-if="loading" color="primary" indeterminate size="40" width="2"></v-progress-circular>

        <v-card v-if="!loading && printer !== null">
          <v-card-text>

            <v-row>
              <v-col cols="12" lg="6" md="12">
                <!-- Name -->
                <v-text-field v-model="printer.name"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              autocomplete="off"
                              :label="$t('settings.lang_printerName')"
                              outlined
                              required
                              @focus="showTouchKeyboard"
                ></v-text-field>

                <!--group by room or cashier id -->
                <v-radio-group @change="initAssignedPrinters" v-model="printer.options.group_by" :label="$t('generic.lang_groupBy')" :value="1"
                               v-if="parseInt(this.printer.type) === 4">
                  <v-radio
                      :label="$t('erp.lang_room')"
                      :value="1"
                  ></v-radio>

                  <v-radio
                      :label="$t('generic.lang_cashierID')"
                      :value="2"
                  ></v-radio>
                </v-radio-group>

                <!-- Gateway -->
                <v-radio-group v-if="parseInt(this.printer.type) !== 4 && parseInt(this.printer.type) !== 5"
                               v-model="printer.gateway"
                               :label="$t('settings.lang_printerGateway')">
                  <v-radio :label="$t('settings.lang_bluetooth_onlyInTheApp')" value="bluetoothApp"></v-radio>
                  <v-radio :label="'EPSON ePOS(' + $t('settings.lang_network') + ')'" value="epos"></v-radio>
                  <v-radio label="ESC/POS" value="escpos"></v-radio>
                  <v-radio :label="'ESC/POS ' + $t('settings.lang_external')" value="escposexternal"></v-radio>
                </v-radio-group>

                <!-- IP Adresse -->
                <v-text-field v-if="printer.gateway === 'epos' && parseInt(this.printer.type) !== 4 && parseInt(this.printer.type) !== 5"
                              v-model="printer.address"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              autocomplete="off"
                              :label="$t('settings.lang_printerManagerIPAdress')"
                              outlined
                              placeholder="192.168.178.45"
                              required
                              @focus="showTouchKeyboard"
                ></v-text-field>

                <template v-if="printer.gateway === 'escpos' && parseInt(this.printer.type) !== 4 && parseInt(this.printer.type) !== 5">
                  <!-- IP Adresse -->
                  <v-text-field
                      v-model="printer.address"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      autocomplete="off"
                      :label="$t('settings.lang_printerManagerIPAdress')"
                      outlined
                      placeholder="192.168.178.45"
                      required
                      @focus="showTouchKeyboard"
                  ></v-text-field>
                </template>
                <template v-if="(printer.gateway === 'escposexternal' && parseInt(this.printer.type) !== 4) || parseInt(this.printer.type) === 5">

                  <v-radio-group v-if="parseInt(this.printer.type) !== 5" v-model="printer.externalESCType" :label="$t('settings.lang_typeOfExternalESCPosPrinter')" row>
                    <v-radio :label="$t('settings.lang_network')" value="network"></v-radio>
                    <v-radio label="USB" value="usb"></v-radio>
                  </v-radio-group>
                  <v-row v-if="printer.externalESCType==='usb' && parseInt(this.printer.type) !== 5" class="mb-4">
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          class="mx-0"
                          v-model="printer.usbId1"
                          autocomplete="off"
                          label="USB HID 1"
                          outlined
                          required
                          hide-details
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"

                      ></v-text-field>
                    </v-col>

                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          class="mx-0"
                          v-model="printer.usbId2"
                          autocomplete="off"
                          label="USB HID 2"
                          outlined
                          required
                          hide-details
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"

                      ></v-text-field><br><br>
                    </v-col>
                  </v-row>
                  <!-- IP Adresse -->
                  <v-text-field
                      v-if="printer.externalESCType==='network' || parseInt(this.printer.type) === 5"
                      v-model="printer.address"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      autocomplete="off"
                      :label="$t('settings.lang_printerManagerIPAdress')"
                      outlined
                      placeholder="192.168.178."
                      required
                      @focus="showTouchKeyboard"
                  ></v-text-field>
                  <!-- IP Adresse -->
                  <v-text-field
                      v-model="printer.serverAddress"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      autocomplete="off"
                      :label="$t('settings.lang_printServerIPAddress')"
                      outlined
                      placeholder="127.0.0.1"
                      required
                      @focus="showTouchKeyboard"
                  ></v-text-field>
                </template>



                <!-- PapierTyp -->
                <v-radio-group v-if="printer.type === 1 || printer.type === 2" v-model="printer.paperType"
                               :label="$t('settings.lang_printerManagerPaperType')">
                  <v-radio :value="80" label="80mm"></v-radio>
                  <v-radio :value="50" label="50mm"></v-radio>
                </v-radio-group>

                <!-- KassenID -->
                <v-select
                    v-if="printer.type === 1 || parseInt(this.printer.type) === 5"
                    v-model="printer.cashierID"
                    :items="filteredCashierIDs"
                    :label="$t('generic.lang_cashierID')"
                    multiple
                    outlined
                ></v-select>
              </v-col>

              <v-col cols="12" lg="6" md="12">
                <!-- KassenDrucker -->
                <div v-if="parseInt(this.printer.type) !== 5">
                  <v-checkbox v-model="printer.options.autoPrint" :false-value="0" :true-value="1"
                              :label="$t('generic.lang_autoPrint')" style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.sslEnabled" :false-value="0" :true-value="1"
                              :label="$t('generic.lang_SecureConnection')" style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.disableFiscalQrCode" :false-value="0" :true-value="1" :label="$t('generic.lang_disableQrCodePrinting')"
                              style="margin-top: 0;"></v-checkbox>
                </div>
                <!-- OrderBon Drucker -->
                <div v-if="printer.type === 2 || printer.type === 4">
                  <v-checkbox v-model="printer.options.lineBreak" :false-value="0"
                              :label="this.$t('settings.lang_printerManagerTrimWarename')"
                              :true-value="1"
                              style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.autoPaperCut" :false-value="0"
                              :label="this.$t('settings.lang_printerManagerCutAfterEachPosition')"
                              :true-value="1"
                              style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.showItemPrices" :false-value="0"
                              :label="this.$t('settings.lang_printerManagerDisplayPricesOrderbon')"
                              :true-value="1"
                              style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.showPLU" :false-value="0"
                              :label="this.$t('settings.lang_printerManagerDisplayPLUonOrderbon')"
                              :true-value="1"
                              style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.hideItemNames" :false-value="0"
                              :label="this.$t('settings.lang_printerManagerHideWareName')"
                              :true-value="1"
                              style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.coursesOnOneBon" :false-value="0"
                              :label="this.$t('settings.lang_printCoursesOnOneOrderbon')"
                              :true-value="1"
                              style="margin-top: 0;"></v-checkbox>

                  <v-text-field v-model="printer.options.duplicateOrderBonPrint"
                              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                              autocomplete="off"
                              outlined
                              placeholder="192.168.178.45"
                              :rules="[rules.numberRange]"
                              type="number"
                              @focus="showTouchKeyboard"
                              :label="$t('settings.lang_timesToDuplicateOrderbon')" style="margin-top: 0;"></v-text-field>
                  <v-checkbox v-model="printer.options.playBuzzer" :false-value="0" :true-value="1"
                              :label="$t('settings.lang_playBuzzerSound')"
                              style="margin-top: 0;"></v-checkbox>
                  <v-checkbox v-model="printer.options.printWaiterOrderBon" :false-value="0" :true-value="1"
                              :label="$t('settings.lang_waiterBonPrint')"
                              style="margin-top: 0;"></v-checkbox>
                </div>

                <div v-if="printer.type !== 4 && printer.type !== 5">
                  <v-checkbox v-model="printer.options.showCashierAlias" :false-value="0" :true-value="1"
                              :label="this.$t('settings.lang_printerManagerShowCashierAlias')"
                              style="margin-top: 0;"></v-checkbox>

                  <v-checkbox v-model="printer.options.showWaiterName" :false-value="0" :true-value="1"
                              :label="this.$t('settings.lang_printerManagerShowWaiterName')"
                              style="margin-top: 0;"></v-checkbox>

                  <v-checkbox v-model="printer.options.showRoomNumber" :false-value="0" :true-value="1"
                              :label="this.$t('settings.lang_printerManagerShowRoomNumber')"
                              style="margin-top: 0;"></v-checkbox>
                </div>
              </v-col>

              <v-col v-if="!!this.printer && parseInt(this.printer.type) == 1" class="pt-0 pb-0 mt-0 mb-0" cols="12"
                     md="4" sm="6">
                <v-checkbox v-model="printer.options.translateWareName" :false-value="0"
                            :label="this.$t('settings.lang_printerManagerTranslateWarename')"
                            :true-value="1"/>
              </v-col>

              <v-col cols="12" sm="6" v-if="parseInt(this.printer.type) !== 4 && parseInt(this.printer.type) !== 5">
                <v-select v-model="printer.alternative_orderbon_printer" :items="this.Printers"
                          :label="this.$t('settings.lang_printerManagerAlternativePrinter')"
                          :loading="this.loading"
                          dense item-text="name"
                          item-value="id"
                          outlined/>
              </v-col>

              <v-col cols="12" sm="6">

              </v-col>


              <v-col v-if="printer.type !== 4 && printer.type !== 5" class="pt-0 pb-0 mt-0 mb-0" cols="12" md="4" sm="6">
                <v-checkbox v-model="printer.options.orderbon_printAllPositions" :false-value="0" :true-value="1"
                            :label="this.$t('settings.lang_orderbonPrintAllPositions')"/>

                <v-checkbox v-model="printer.options.showSellerName" :false-value="0" :true-value="1"
                            :label="this.$t('settings.lang_printerManagerShowSellerName')"
                            style="margin-top: 0;"></v-checkbox>
              </v-col>


              <v-col v-if="printer.type !== 4 && printer.type !== 5" cols="12">
                <v-btn class="bg-primary text-light" :loading="loadingTest"
                       @click="testPrint">
                  <v-icon class="ma-1">visibility</v-icon>
                  {{ $t('settings.lang_printerManagerPrinterTest') }}
                </v-btn>


                <v-btn v-if="!!this.printer && parseInt(this.printer.type) == 1"
                       class="bg-secondary text-light"
                       @click="redirect">
                  <v-icon class="ma-1">cloud_upload</v-icon>
                  {{ $t('settings.lang_uploadPrinterLogo') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-data-table v-if="printer.options.group_by === 1" :items-per-page="-1" :headers="this.dataTableRooms"
                          :items="this.rooms">
              <template v-slot:item.printer_name="{item}">
                <v-select hide-details :label="$t('generic.lang_selectPrinter')" item-text="name" item-value="id" :items="Printers"
                          outlined dense
                          v-model="item.printer_id"/>
              </template>
            </v-data-table>


            <v-data-table v-else-if="printer.options.group_by === 2" :items-per-page="-1"
                          :headers="this.dataTableCashiers"
                          :items="this.cashiers">
              <template v-slot:item.printer_name="{item}">
                <v-select hide-details :label="$t('generic.lang_selectPrinter')" item-text="name" item-value="id" :items="Printers"
                          outlined dense
                          v-model="item.printer_id"/>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer pt-0 pb-0">
            <v-spacer></v-spacer>
            <v-btn :disabled="editLoading || printer.name.length === 0" :loading="editLoading" color="success"
                   @click="savePrinter">{{$t('generic.lang_saveChanges')}}
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-card-text>
      <v-dialog :value="errorDialogMessage.length > 0" max-width="300">
        <v-card>
          <v-card-title>{{ $t('generic.lang_warning') }}</v-card-title>
          <v-card-text>
            {{ errorDialogMessage }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="errorDialogMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidth"/>
      </div>
    </v-card>

  </v-container>
</template>

<script>
import mixin from "../../../../mixins/KeyboardMixIns";
import {createNamespacedHelpers, mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {printDataFromPrinter} from "../../../../plugins/printing/printerController";

export default {
  name: "EditPrinter",
  mixins: [mixin],
  computed: {
    ...mapState([
      'api'
    ]),
    ...createNamespacedHelpers('printer').mapGetters([
      'printers'
    ]),
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierID) => {
        return {
          value: parseInt(cashierID),
          text: this.$t('generic.lang_kasse') + " " + cashierID
        };
      });
    }
  },

  data() {
    return {
      loading: false,
      loadingTest: false,
      editLoading: false,
      errorDialogMessage: "",
      Printers: [],
      rooms: [],
      printer: null,
      cashierIDs: [],
      cashiers: [],
      dataTableRooms: [
        {
          text: this.$t('erp.lang_rooms'),
          value: "name",
          sortable: false
        },
        {
          text: this.$t('settings.lang_printers'),
          value: "printer_name",
          sortable: false
        }
      ],
      dataTableCashiers: [
        {
          text: this.$t('settings.lang_cashiersId'),
          value: "id",
          sortable: false
        },
        {
          text: this.$t('generic.lang_alias'),
          value: "aliasName",
          sortable: false
        },
        {
          text: this.$t('settings.lang_printers'),
          value: "printer_name",
          sortable: false
        }
      ],
      rules:{
        numberRange : (v) => {
          return (Number(v)>=1 && Number(v)<=10) || this.$t('settings.lang_thisFieldShouldBe1AtMinAnd10AtMax');
        }
      }
    }
  },

  mounted() {
    this.getPrinter();
    //this.getRooms();
    this.loadPrinters();
    this.getCashiers();
    /* this.cashiers = [...this.$store.getters['cashierIDs/cashiers']];
     if (Array.isArray(this.cashiers))
       this.cashiers = this.cashiers.map(
           ({id, aliasName}) => ({
             id: id,
             aliasName: aliasName,
             //printer_id: null
           })
       )*/
  },

  methods: {
    /**
     * get all available rooms
     */
    getRooms() {
      return new Promise((resolve) => {
        this.loading = true;
        this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.GET_ALL)
            .then(res => {
              resolve(res.data.roomInformations);
            }).catch(err => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        });
      })
    },
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
    testPrint() {

      this.loadingTest = true;
      let printData;

      let data = {
        saleID: "00000",
        cashierID: "Testkasse",
        userID: 101,
        tableNo: "Testtisch",
        ts: 1577836800,
        moneyGiven: 99.99,
        paymentType: 1
      };

      let options = {
        paperType: 80
      };
      let invoiceItemsToPrint = [];
      printData = createInvoicePrintingData(invoiceItemsToPrint, data, options);

      printData.push({
        cut: true
      });
      this.printer.api=this.printer.gateway;
      printDataFromPrinter(this.printer,printData).then((res)=>{
      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t("generic.lang_anErrorOccurredPLSTryAgain"),
          color: "error"
        });
      }).finally(()=>{
        this.loadingTest = false;
      })

    },
    redirect() {
      let protocol = this.printer.options.sslEnabled == 1 ? "https" : "http";
      let a = document.createElement('a');
      a.href = this.axios.defaults.baseURL + "/pages/view/printerlogos/?protocol=" + protocol + "&ip=" + this.printer.address + "&logoName=" + this.printer.receiptLogo + "&bs=" + this.axios.defaults.headers.common['3POSBS'];
      a.setAttribute('target', '_blank');
      a.click();
      a.remove();
    },
    loadPrinters() {
      this.loading = true;
      this.axios.post(ENDPOINTS.DATATABLES.PRINTSETTINGS.PRINTER + "?iDisplayStart=0&iDisplayLength=10000").then((res) => {
        this.Printers = res.data.aaData.map((elt) => {
          return {
            id: Number(elt[0]),
            name: elt[1],
            type: elt[2]
          }
        }).filter((elt) => Number(elt.id) !== Number(this.$route.params.id) && elt.type !== 'virtueller Orderbon Drucker' );
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    async initAssignedPrinters() {


      // start if grouped by rooms
      if (this.printer.options.group_by === 1) {
        await this.getRooms().then((rooms) => {

          if (Array.isArray(rooms)) {
            //let add a default room to assign default printers
            if (!Array.isArray(rooms)) rooms = [];
            rooms.unshift([
              "default",
              "Default Room"
            ]);
            this.rooms = rooms.map((room) => {
              //find assigned room

              return {
                id: room[0],
                name: room[1],
                printer_id: null
              }

            });
          }
        })
      }
          // end if grouped by rooms
      // start if grouped by cashier ids
      else if (this.printer.options.group_by === 2) {

        if (Array.isArray(this.$store.getters['cashierIDs/cashiers'])) {

          //exclude virtual printers
          let cashiersList = this.$store.getters['cashierIDs/cashiers'].filter(({type}) => Number(type) !== 4);
          if (!Array.isArray(cashiersList)) cashiersList = [];
          //add default cashier
          cashiersList.unshift({
                id: "default",
                aliasName: "Default Cashier",
                printer_id: null
              }
          );


          this.cashiers = cashiersList.map(
              ({id, aliasName}) => {

                return {
                  id: id,
                  aliasName: aliasName,
                  printer_id: null
                }

              }
          )

        }
      }
      // end if grouped by cashier ids

      this.$forceUpdate();
    },
    async filterAssignedPrinters() {
      //filter assigned printers
      if (this.printer && typeof this.printer.virtualOrderbonPrinterAssignment === 'object') {

        // start if grouped by rooms
        if (this.printer.options.group_by === 1) {
          await this.getRooms().then((rooms) => {

            if (Array.isArray(rooms)) {
              //let add a default room to assign default printers
              if (!Array.isArray(rooms)) rooms = [];
              rooms.unshift([
                "default",
                "Default Room"
              ]);

              this.rooms = rooms.map((room) => {
                //find assigned room
                if(!this.printer.virtualOrderbonPrinterAssignment.rooms){
                  return {
                    id: room[0],
                    name: room[1],
                    printer_id: null
                  }
                }
                let tmp = this.printer.virtualOrderbonPrinterAssignment.rooms.find(({id}) => id === room[0])
                if (tmp) {
                  return {...tmp}
                } else {
                  return {
                    id: room[0],
                    name: room[1],
                    printer_id: null
                  }
                }
              });

            }
          })


        }

            // end if grouped by rooms
        // start if grouped by cashier ids
        else if (this.printer.options.group_by === 2) {

          if (Array.isArray(this.$store.getters['cashierIDs/cashiers'])) {
            //exclude virtual printers
            let cashiersList = this.$store.getters['cashierIDs/cashiers'].filter(({type}) => Number(type) !== 4);
            if (!Array.isArray(cashiersList)) cashiersList = [];
            //add default cashier
            cashiersList.unshift({
                  id: "default",
                  aliasName: "Default Cashier",
                  printer_id: null
                }
            );


            this.cashiers = cashiersList.map(
                ({id, aliasName}) => {
                  //find assigned cashier
                  if(!this.printer.virtualOrderbonPrinterAssignment.cashiers){
                    return {
                      id: id,
                      aliasName: aliasName,
                      printer_id: null
                    }
                  }
                  let tmp = this.printer.virtualOrderbonPrinterAssignment.cashiers.find((c) => c.id === id);

                  if (tmp) {
                    return {...tmp}
                  } else {
                    return {
                      id: id,
                      aliasName: aliasName,
                      printer_id: null
                    }
                  }
                }
            )

          }
        }
        // end if grouped by cashier ids
      }
      this.$forceUpdate();
    },
    async getPrinter() {
      this.loading = true;

      this.axios.post(ENDPOINTS.PRINTSETTINGS.PRINTER.GET, {
        printerID: parseInt(this.$route.params.id)
      }).then(async (res) => {
        if (res.data.success) {
          this.printer = res.data.printer;
          await this.filterAssignedPrinters();
        } else {
          this.errorDialogMessage = res.data.msg;
        }
      }).catch((e) => {
        console.log(e);
        this.errorDialogMessage = this.$t('generic.lang_errorOccurred');
      }).finally(() => this.loading = false)
    },
    savePrinter() {
      this.editLoading = true;

      this.printer.assigns = {
        rooms: [],
        cashiers: []
      };

      if (this.printer.options.group_by === 1) {
        this.printer.assigns.rooms = this.rooms.filter(
            ({printer_id}) => parseInt(printer_id) > 0
        );

        delete this.printer.assigns.cashiers;
      }
      if (this.printer.options.group_by === 2) {
        this.printer.assigns.cashiers = this.cashiers.filter(
            ({printer_id}) => parseInt(printer_id) > 0
        );
        delete this.printer.assigns.rooms;
      }

      this.printer.externalType = this.printer.externalESCType;
      this.printer.usbField1 = this.printer.usbId1;
      this.printer.usbField2 = this.printer.usbId2;

      this.axios.post(ENDPOINTS.PRINTSETTINGS.PRINTER.UPDATE, this.printer).then((res) => {
        if (!res.data.success) {
          this.errorDialogMessage = res.data.msg;
        }

        this.$router.push({
          name: "settings.printerSettings.printerManager"
        });

        this.editLoading = false;
      }).catch(() => {
        this.errorDialogMessage = this.$t('generic.lang_anErrorOccurredPLSTryAgain');

        this.editLoading = false;
      })
    }
  }
}
</script>
